import { getAdminUsers } from "@/http/api/admin";
import type { LoaderFunctionArgs } from "react-router-dom";

export default async function adminUsersLoader({
  request,
}: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const param = url.searchParams;
  try {
    const paramExport = param.get("export");
    if (paramExport) {
      const response = await getAdminUsers(
        { export: paramExport },
        { signal: request.signal },
      );
      return await handleEmailResponse(response, paramExport);
    }
  } catch (e) {
    console.log(e);
    throw new Error("Failed to download the file");
  }

  try {
    const paramDelete = param.get("delete");
    if (paramDelete) {
      await getAdminUsers({ delete: paramDelete }, { signal: request.signal });
      return { message: "User deleted successfully" };
    }
  } catch (e) {
    console.log(e);
    throw new Error("Failed to delete the file");
  }
  const response = await getAdminUsers(undefined, { signal: request.signal });
  return response;
}

const handleEmailResponse = async (response: Response, email: string) => {
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", `${email}.zip`); // Set the file name for download
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(downloadUrl);
  return { message: "Download complete" };
};
